<template>
  <div class="pt-32pt">
    <div :class="containerClass" class="d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
      <div :class="{ 'mb-24pt mb-md-0': !!buttonLabel }" class="flex d-flex flex-column flex-sm-row align-items-center">
        <div class="mb-24pt mb-sm-0 mr-sm-24pt">
          <page-heading class="mb-0" :element="pageHeadingElement" :element-props="pageHeadingElementProps">
            {{ title }}
            <i
              v-if="infoText.length"
              class="material-icons icon-16pt clickable-item"
              v-b-popover.hover.top
              :title="infoText"
              >info</i
            >
          </page-heading>

          <b-breadcrumb v-if="breadcrumb" :items="breadcrumb" class="mb-0" />
        </div>
      </div>

      <div v-if="buttonLabel" class="row">
        <div class="col-auto"><b-btn :to="buttonPath" :variant="buttonVariant" v-text="buttonLabel" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeading from './PageHeading.vue';

export default {
  components: {
    PageHeading,
  },
  mixins: [],
  props: {
    buttonLabel: {
      type: String,
      default: null,
    },
    buttonPath: {
      type: [String, Object],
      default: null,
    },
    buttonVariant: {
      type: String,
      default: 'outline-secondary',
    },
    breadcrumb: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: null,
    },
    containerClass: {
      type: String,
      default: null,
    },
    pageHeadingElement: {
      type: [String, Object],
    },
    pageHeadingElementProps: {
      type: Object,
    },
    infoText: { type: String, default: '' },
  },
};
</script>
