<template>
  <b-pagination
    v-model="currentPage"
    :total-rows="totalRows"
    :per-page="perPage"
    size="xsm"
    hide-goto-end-buttons
    @change="(arg) => $emit('change', arg)"
  >
    <template #prev-text>
      <md-icon aria-hidden="true">chevron_left</md-icon>
      <span>{{ $t('pagination.prev') }}</span>
    </template>
    <template #next-text>
      <span>{{ $t('pagination.next') }}</span>
      <md-icon aria-hidden="true">chevron_right</md-icon>
    </template>
  </b-pagination>
</template>

<script>
import MdIcon from './MdIcon.vue';

export default {
  name: 'Pagination',
  components: { MdIcon },
  model: { prop: 'currentPage', event: 'change' },
  props: {
    perPage: { type: Number, default: 15 },
    currentPage: { type: Number, default: 1 },
    totalRows: { type: Number, default: 0 },
  },
};
</script>