<template>
  <div :class="containerClass">
    <page-header
      title="Manage Payments"
      :container-class="null"
      class="mb-32pt"
      button-label="Payment History"
      :button-path="{ name: 'manage-payment-history' }"
      button-variant="secondary"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Access a comprehensive overview of your payment history and relevant stats.`
          : ''
      "
    />

    <page-separator title="" />

    <div
      class="page-section border-bottom-2"
      v-if="
        isLoading ||
        !((!getPayoutAccountStatus && !isVerifying) || getPayoutAccountStatus === PAYOUT_ACC_STATUS.UNLINKED)
      "
    >
      <div class="row mb-4">
        <div class="col-lg-6">
          <div class="card text-center mb-lg-0">
            <div class="card-body">
              <b-skeleton-wrapper :loading="isSummaryLoading || isLoading">
                <template #loading>
                  <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                </template>

                <h4 class="h2 mb-0">{{ $n(total, 'currency', 'en-US') }}</h4>
              </b-skeleton-wrapper>

              <span class="d-flex justify-content-center" v-if="expressLink">
                <span>Total Balance</span>
                <span class="mx-2">-</span>

                <a :href="expressLink" class="text-primary mt-0 icon-align" target="_blank">
                  Dashboard <i class="material-icons icon-16pt">open_in_new</i></a
                >
              </span>
              <span v-else>Total Balance</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card text-center mb-lg-0">
            <div class="card-body">
              <b-skeleton-wrapper :loading="isSummaryLoading || isLoading">
                <template #loading>
                  <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                </template>

                <h4 class="h2 mb-0">{{ $t(`intervalTypes.${payout_interval}`) }}</h4>
              </b-skeleton-wrapper>
              <div>Payout Frequency</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="card text-center mb-lg-0">
            <div class="card-body">
              <b-skeleton-wrapper :loading="isSummaryLoading || isLoading">
                <template #loading>
                  <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                </template>

                <h4 class="h2 mb-0">{{ $n(balance, 'currency', 'en-US') }}</h4>
              </b-skeleton-wrapper>
              <div class="icon-align">
                <div>
                  Available Balance
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="This balance is available for payout and will be included in the next payout."
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card text-center mb-lg-0">
            <div class="card-body">
              <b-skeleton-wrapper :loading="isSummaryLoading || isLoading">
                <template #loading>
                  <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                </template>

                <h4 class="h2 mb-0">{{ $n(pending_amount, 'currency', 'en-US') }}</h4>
              </b-skeleton-wrapper>
              <div class="icon-align">
                <div>
                  Pending Balance
                  <i
                    class="fas fa-info-circle clickable-item text-black-50"
                    v-b-popover.hover.top
                    title="This balance is in review and currently pending for payout."
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-credit-card fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />

              <p><b-skeleton width="60%"></b-skeleton></p>
              <p><b-skeleton width="90%"></b-skeleton></p>
              <p><b-skeleton width="75%"></b-skeleton></p>
              <div class="d-flex justify-content-center">
                <b-skeleton type="button" width="250px"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div
        class="d-flex justify-content-center"
        v-if="(!getPayoutAccountStatus && !isVerifying) || getPayoutAccountStatus === PAYOUT_ACC_STATUS.UNLINKED"
      >
        <div class="card card-block card-stretch card-height col-md-12">
          <div class="card-body text-center">
            <i class="fab fa-cc-stripe fa-lg text-black mt-4 mb-2 col-md-8" style="font-size: 4.3rem" />
            <h4>Connect Stripe Account</h4>
            <p class="pl-3 pr-3">
              Transition Enroll partners with Stripe for fast, secure payments. Click below to configure your payout
              information.
            </p>
            <b-btn
              variant="primary"
              @click.prevent="initiateLinkPayoutInfo"
              class="btn-normal"
              style="width: 180px"
              :disabled="isLinking"
            >
              <i v-if="isLinking" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Connect Stripe</span>
            </b-btn>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" v-else>
        <div class="card card-block card-stretch card-height col-md-12">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <i class="fab fa-cc-stripe icon-12pt mr-2" style="font-size: 1.5rem"></i>

              <span
                class="flex"
                style="white-space: nowrap"
                v-if="getPayoutAccountStatus === PAYOUT_ACC_STATUS.VERIFIED"
              >
                Your Stripe account has been linked and verified.
              </span>
              <span v-else
                >Your account has been linked but pending Stripe verification. Click below to verify your account.
              </span>
            </div>

            <p class="text-muted mt-1">
              Note: To change the default payout method, remove the existing information and add a new one.
            </p>

            <div class="d-flex justify-content-end">
              <b-btn
                v-if="getPayoutAccountStatus === PAYOUT_ACC_STATUS.LINKED || isVerifying"
                variant="primary"
                @click.prevent="verifyPayOutAcc"
                class="btn-normal mr-2"
                :disabled="isVerifying"
                style="width: 180px"
              >
                <i v-if="isVerifying" class="fas fa-circle-notch fa-spin"></i>
                <span v-else>Verify Account</span>
              </b-btn>
              <b-btn
                variant="danger"
                @click.prevent="unlinkPayoutInfo"
                class="btn-normal"
                :disabled="isUnlinking || isVerifying"
                style="width: 180px"
              >
                <i v-if="isUnlinking" class="fas fa-circle-notch fa-spin"></i>
                <span v-else>Disconnect Stripe</span>
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import { get } from 'lodash';
import { PAYOUT_ACC_STATUS, USER_ROLE_TYPES } from '@/common/constants';
export default {
  name: 'PayoutInfoPage',
  components: {
    PageSeparator,
    PageHeader,
  },
  extends: Page,

  data() {
    return {
      title: 'Payout Info',
      isLoading: false,
      isLinking: false,
      isUnlinking: false,
      isVerifying: false,
      PAYOUT_ACC_STATUS,
      isSummaryLoading: false,
      balance: null,
      pending_amount: null,
      payout_interval: null,
      total: null,
      expressLink: null,
      isExpressLinkLoading: false,
      USER_ROLE_TYPES,
    };
  },

  computed: {
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Payments', active: true },
      ];
    },
  },
  methods: {
    get,
    ...mapActions('payoutAcc', [
      'verifyAccount',
      'createPayoutAccount',
      'deletePayoutAcc',
      'getSummary',
      'getExpressDashboardLink',
    ]),
    async fetchAccountSummary() {
      this.isSummaryLoading = true;
      try {
        const response = await this.getSummary();

        this.balance = response.data.balance;
        this.total = response.data.total;
        this.pending_amount = response.data.pending_amount;
        this.payout_interval = get(response, 'data.schedule.interval');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isSummaryLoading = false;
    },
    async fetchExpressDashboardLink() {
      this.isExpressLinkLoading = true;
      try {
        const resp = (await this.getExpressDashboardLink()).data;
        if (resp.url) {
          this.expressLink = resp.url;
        }
      } catch (err) {
        // TODO: handle error if needed.
        // this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isExpressLinkLoading = false;
    },
    async unlinkPayoutInfo() {
      this.isUnlinking = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        "Please confirm that you want to remove your default payout account. You won't be able to offer any program unless you link a new account.",
        {
          title: 'Unlink Payout Account',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        try {
          await this.deletePayoutAcc();
          this.makeToast({ variant: 'success', msg: 'Payout Account Unlinked!' });
        } catch (_err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isUnlinking = false;
    },
    async verifyPayOutAcc() {
      this.isVerifying = true;
      try {
        const resp = await this.verifyAccount();
        if (resp.status === PAYOUT_ACC_STATUS.VERIFIED) {
          this.makeToast({ variant: 'success', msg: 'Payout Info verified!' });
        } else
          this.makeToast({
            variant: 'danger',
            msg: 'Unable to confirm verification from Stripe. Please try again.',
          });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isVerifying = false;
    },

    async initiateLinkPayoutInfo() {
      this.isLinking = true;
      try {
        const resp = (await this.createPayoutAccount()).data;
        if (resp.data.url) {
          window.location.href = resp.data.url;
        } else {
          this.makeToast({ variant: 'danger', msg: 'Cannot generate account link. Please try again later.' });
          this.isLinking = false;
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.isLinking = false;
      }
    },
  },

  async mounted() {
    this.fetchAccountSummary();
    this.fetchExpressDashboardLink();
    this.isLoading = true;
    if (this.$route.query.status === 'return') {
      try {
        const resp = await this.verifyAccount();
        if (resp.status === PAYOUT_ACC_STATUS.LINKED) {
          this.makeToast({ variant: 'warning', msg: 'Your account has been linked but pending verification!' });
        } else if (resp.status === PAYOUT_ACC_STATUS.VERIFIED) {
          this.makeToast({ variant: 'success', msg: 'Your account has been verified!' });
        } else this.makeToast({ variant: 'danger', msg: 'Cannot save payout information. Please try again.' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.$router.push({ name: this.$route.name });
    } else if (this.$route.query.status === 'refresh') {
      this.initiateLinkPayoutInfo();
    } else {
      await this.verifyAccount();
    }
    this.isLoading = false;
  },
};
</script>
